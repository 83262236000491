<template>
    <div>
        <b-overlay :show="loadingPrivado" variant="white" rounded="sm">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-alert
                                :show="segundosMensajeActualizacion"
                                dismissible
                                :variant="mensaje.variant"
                                @dismissed="segundosMensajeActualizacion = 0"
                                @dismiss-count-down="
                                    actualizarSegundosMensajeActualizacion
                                "
                            >
                                {{ mensaje.texto }}
                            </b-alert>
                            <div class="d-flex">
                                <div
                                    class="card-title col-auto me-auto titulo-tarjetas"
                                >
                                    Documentos Proyecto
                                </div>
                            </div>
                            <div
                                class="alert-danger p-3"
                                role="alert"
                                v-if="!mostrarMensajeDocumentos"
                            >
                                No Hay documentos para la fecha seleccionada
                            </div>
                            <div v-if="mostrarMensajeDocumentos">
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div
                                            id="tickets-table_length"
                                            class="dataTables_length"
                                        >
                                            <label
                                                class="d-inline-flex align-items-center"
                                            >
                                                Mostrar&nbsp;
                                                <b-form-select
                                                    class="form-select form-select-sm"
                                                    v-model="perPage"
                                                    size="sm"
                                                    :options="pageOptions"
                                                ></b-form-select>
                                            </label>
                                        </div>
                                    </div>

                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div
                                            id="tickets-table_filter"
                                            class="dataTables_filter text-md-end"
                                        >
                                            <label
                                                class="d-inline-flex align-items-center"
                                            >
                                                <b-form-input
                                                    v-model="filter"
                                                    type="search"
                                                    placeholder="Buscar..."
                                                    class="form-control"
                                                ></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-12 col-md-3">
                                        <label for="filtrarTipoDocumento"
                                            >Filtrar Tipo Documento</label
                                        >
                                        <multiselect
                                            @input="
                                                filtrarDocumentosTipoDocumento()
                                            "
                                            v-model="filtroTipoDocumento"
                                            :options="filtroTipoDocumentos"
                                            placeholder="Tipo Documento"
                                            value="tipo_documento_id"
                                            label="nombre_tipo_documento"
                                            :searchable="true"
                                            class="helo"
                                        ></multiselect>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="filtrarNombreDocumento"
                                            >Filtrar Nombre Documento</label
                                        >
                                        <multiselect
                                            @input="
                                                filtrarDocumentosNombreDocumento()
                                            "
                                            v-model="filtroNombreDocumento"
                                            :options="filtroNombreDocumentos"
                                            placeholder="Nombre Documento"
                                            value="nombre_documento_id"
                                            label="nombre_documento"
                                            :searchable="true"
                                            class="helo"
                                        ></multiselect>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <button
                                            class="btn btn-primary "
                                            style="margin-top:27px"
                                            @click="limpiarFiltro"
                                        >
                                            Limpiar
                                        </button>
                                    </div>
                                </div>
                                <!-- Table -->
                                <div class="table-responsive mb-0">
                                    <b-table
                                        id="tabla-documentos"
                                        class="datatables tabla-personalizada"
                                        :items="documentos"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        select-mode="single"
                                        selectable
                                        @filtered="onFiltered"
                                        :busy="documentosCargando"
                                    >
                                        <template #table-busy>
                                            <div
                                                class="text-center text-danger my-2"
                                            >
                                                <b-spinner
                                                    class="align-middle"
                                                ></b-spinner>
                                            </div>
                                        </template>
                                        <template
                                            #cell(accion)="data"
                                            v-if="rolModuloPermiso.escritura"
                                        >
                                            <!-- <i
                                            class="bx bx-edit text-primary pointer icono-botom-tabla"
                                            @click="abrirModalEditar(data.item)"
                                        ></i> -->
                                            <i
                                                class="bx bx-trash text-primary pointer icono-botom-tabla"
                                                @click="
                                                    eliminarDocumentoLocal(
                                                        data.item
                                                    )
                                                "
                                            ></i>
                                        </template>

                                        <template #cell(documento_id)="data">
                                            <a
                                                target="_blank"
                                                :href="urlDocumento(data.item)"
                                            >
                                                <i
                                                    class="mdi h3 pointer me-2"
                                                    :class="
                                                        seleccionarIconoDocumento(
                                                            data.item.extension
                                                        )
                                                    "
                                                ></i
                                            ></a>
                                        </template>
                                        <template
                                            #cell(nombre_tipo_documento)="data"
                                        >
                                            <span
                                                class="badge bg-success font-size-13"
                                                >{{
                                                    data.item
                                                        .nombre_tipo_documento
                                                }}</span
                                            >
                                        </template>
                                        <template
                                            #cell(privado)="data"
                                            v-if="rolModuloPermiso.escritura"
                                        >
                                            <div
                                                class="form-check form-switch form-switch-lg mb-3"
                                            >
                                                <input
                                                    @click="
                                                        actualizarEstadoPrivado(
                                                            data.item
                                                        )
                                                    "
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :checked="data.item.privado"
                                                />
                                            </div>
                                        </template>
                                        <template #cell(fecha_limite)="data">
                                            {{
                                                data.item.fecha_limite != null
                                                    ? formatDate(
                                                          data.item.fecha_limite
                                                      )
                                                    : ""
                                            }}
                                        </template>
                                        <template #cell(fecha_subida)="data">
                                            {{
                                                formatDate(
                                                    data.item.fecha_subida
                                                )
                                            }}
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div
                                            class="dataTables_paginate paging_simple_numbers float-end"
                                        >
                                            <ul
                                                class="pagination pagination-rounded mb-0"
                                            >
                                                <!-- pagination -->
                                                <b-pagination
                                                    v-model="currentPage"
                                                    :total-rows="totalRows"
                                                    :per-page="perPage"
                                                ></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Adjunto"
            hide-footer
            size="xl"
        >
            <b-overlay :show="loading" variant="white" rounded="sm" no-wrap>
            </b-overlay>
            <form @submit.prevent="submit">
                <div class="card-title-desc">
                    {{ proyectoSeleccionado.nombre_proyecto }} -
                    {{ lugarInstalacion.codigo_proyecto }} -
                    {{ lugarInstalacion.codigo_lugar_instalacion }}
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="tipoDocumento">Tipo Documento</label>
                            <multiselect
                                @input="filtrarNombreDocumentos()"
                                v-model="$v.form.tipoDocumento.$model"
                                :options="tipoDocumentos"
                                placeholder="Seleccionar Tipo Documento"
                                value="tipo_documento_id"
                                label="nombre_tipo_documento"
                                :searchable="true"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.tipoDocumento.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="
                                    submitted && !$v.form.tipoDocumento.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreDocumento"
                                >Nombre Documento</label
                            >
                            <multiselect
                                v-model="$v.form.nombreDocumento.$model"
                                :options="nombreDocumentos"
                                placeholder="Seleccionar Documento"
                                value="nombre_documento_id"
                                label="nombre_documento"
                                :searchable="true"
                                :loading="nombreDocumentosCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.nombreDocumento.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="
                                    submitted &&
                                        !$v.form.nombreDocumento.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="comentario">Comentario</label>
                            <input
                                id="comentario"
                                v-model="$v.form.comentario.$model"
                                type="text"
                                class="form-control"
                                placeholder="Comentario"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.comentario.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.comentario.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fechaLimite">Fecha Limite</label>
                            <b-form-datepicker
                                v-model="$v.form.fechaLimite.$model"
                                placeholder="Fecha Limite"
                                :state="fechaLimiteValidar"
                                class="mb-2"
                                :min="min"
                            ></b-form-datepicker>
                            <div
                                v-if="
                                    submitted && !$v.form.fechaLimite.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fileDocumento">Documento</label>
                            <input
                                class="form-control"
                                type="file"
                                name="fileDocumento"
                                accept=".png, .jpg, .jpeg,.bmp,.txt,.doc,.docx,.xls,.xlsx,.pdf"
                                @change="onFileChange($event)"
                                :class="{
                                    'is-invalid':
                                        submitted && documentoParaSubir == null,
                                }"
                            />
                            <div
                                v-if="submitted && documentoParaSubir == null"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                            <!-- <div
                                v-if="
                                    submitted && !$v.form.fechaLimite.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="false">
                        <div class="mb-3">
                            <label for="privado">Privado</label>
                            <div
                                class="form-check form-switch form-switch-lg mb-3"
                            >
                                <input
                                    v-model="$v.form.privado.$model"
                                    class="form-check-input"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button type="submit" variant="success" class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="mostrarModalImagen"
            title="Imagen"
            hide-footer
            size="lg"
        >
            <img
                v-if="renderImagen"
                :src="urlImagen"
                alt=""
                style="width: 100%; height: 100%"
            />
        </b-modal>

        <b-modal v-model="mostrarModalPDF" title="PDF" hide-footer size="xl">
            <iframe
                :src="
                    `http://docs.google.com/gview?url=${urlPDF}&embedded=true`
                "
                frameBorder="0"
                scrolling="auto"
                height="800px"
                width="100%"
            ></iframe>
        </b-modal>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { documentoMethods, documentoComputed } from "@/state/helpers";
import { tipoDocumentoMethods, tipoDocumentoComputed } from "@/state/helpers";
import {
    nombreDocumentoMethods,
    nombreDocumentoComputed,
} from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {
        Multiselect,
    },
    props: {
        // documentos: Array,
        fechaSubida: String,
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);

        return {
            renderImagen: true,
            urlImagen: "",
            urlPDF: "",
            mostrarModalImagen: false,
            mostrarModalPDF: false,
            loading: false,
            loadingPrivado: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},
            proyectos: [],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "Proyecto",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "documento_id",
                    label: "Documento",
                    sortable: false,
                },
                {
                    key: "codigo_lugar_instalacion",
                    label: "Lugar Instalación",
                    sortable: true,
                },
                {
                    key: "nombre_tipo_documento",
                    sortable: true,
                },
                {
                    key: "nombre_documento",
                    sortable: true,
                },
                {
                    key: "comentario",
                    sortable: true,
                },
                {
                    key: "privado",
                    sortable: true,
                },
                {
                    key: "fecha_limite",
                    sortable: true,
                },
                {
                    key: "fecha_subida",
                    sortable: true,
                },
            ],
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            guardarCerrar: true,
            documentoSeleccionado: {},
            documentos: [],
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            tipoDocumentos: [],
            nombreDocumentos: [],
            tipoDocumentosCargando: false,
            nombreDocumentosCargando: false,
            documentosCargando: false,
            fechaLimiteValidar: null,
            documentoParaSubir: null,
            form: {
                tipoDocumento: null,
                nombreDocumento: null,
                comentario: null,
                privado: null,
                fechaLimite: null,
            },
            rolModuloPermiso: {},
            filtroTipoDocumento: "",
            filtroTipoDocumentos: [],
            filtroNombreDocumento: "",
            filtroNombreDocumentos: [],
            min: minDate,
            proyectoSeleccionado: {},
            mostrarMensajeDocumentos: false,
        };
    },
    validations: {
        form: {
            tipoDocumento: { required },
            nombreDocumento: { required },
            comentario: {},
            privado: {},
            fechaLimite: {},
        },
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }

        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        this.obtenerDocumentosLocal();
        this.obtenerNombreDocumentosLocal();
        this.obtenerTipoDocumentosFiltro();
        this.obtenerNombreDocumentosFiltro();
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...documentoMethods,
        ...tipoDocumentoMethods,
        ...nombreDocumentoMethods,
        ...proyectoMethods,
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        verImagen(item) {
            this.urlImagen = `${process.env.VUE_APP_URL_DOCUMENTOS}${item.documento_id}${item.extension}`;
            this.mostrarModalImagen = true;

            this.renderImagen = false;
            this.$nextTick(() => {
                this.renderImagen = true;
            });
        },
        verPDF(item) {
            this.urlPDF = `${process.env.VUE_APP_URL_DOCUMENTOS}${item.documento_id}${item.extension}`;
            this.mostrarModalPDF = true;
        },
        urlDocumento(item) {
            return `${process.env.VUE_APP_URL_DOCUMENTOS}${item.documento_id}${item.extension}`;
        },
        descargarDocumento() {},
        onFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();
                this.documentoParaSubir = file;
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        abrirModalNuevo() {
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
            this.obtenerTipoDocumentosLocal();
        },
        abrirModalEditar(datos) {
            this.documentoSeleccionado = datos;
            this.resetForm();
            this.obtenerTipoDocumentosLocal();
            this.obtenerNombreDocumentosLocal();

            this.$v.form.comentario.$model = datos.comentario;
            this.$v.form.privado.$model = datos.privado;
            this.$v.form.fechaLimite.$model = datos.fecha_limite;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        limpiarFiltro() {
            this.obtenerDocumentosLocal();
            this.filtroTipoDocumento = null;
            this.filtroNombreDocumento = null;
        },
        filtrarDocumentosTipoDocumento() {
            this.documentosCargando = true;

            if (this.filtroTipoDocumento.tipo_documento_id == 0) {
                this.obtenerDocumentosLocal();
            } else {
                this.obtenerDocumentosTipoDocumento({
                    codigoLugarInstalacion: this.codigoLugarInstalacion,
                    codigoProyecto: this.codigoProyecto,
                    tipo_documento_id: this.filtroTipoDocumento
                        .tipo_documento_id,
                })
                    .then((res) => {
                        this.documentos = res.body;
                        this.totalRows = this.documentos.length;
                        this.documentosCargando = false;
                    })
                    .catch((error) => {
                        this.documentosCargando = false;
                    });
            }
        },
        filtrarDocumentosNombreDocumento() {
            this.documentosCargando = true;

            if (this.filtroNombreDocumento.nombre_documento_id == 0) {
                this.obtenerDocumentosLocal();
            } else {
                this.obtenerDocumentosNombreDocumento({
                    codigoLugarInstalacion: this.codigoLugarInstalacion,
                    codigoProyecto: this.codigoProyecto,
                    nombre_documento_id: this.filtroNombreDocumento
                        .nombre_documento_id,
                })
                    .then((res) => {
                        this.documentos = res.body;
                        this.totalRows = this.documentos.length;
                        this.documentosCargando = false;
                    })
                    .catch((error) => {
                        this.documentosCargando = false;
                    });
            }
        },
        obtenerDocumentosLocal() {
            this.documentosCargando = true;
            this.obtenerDocumentosFechaSubida({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
                fechaSubida: this.fechaSubida,
            })
                .then((res) => {
                    this.mostrarMensajeDocumentos = true;
                    if (res.body.length == 0) {
                        this.mostrarMensajeDocumentos = false;
                    }
                    if (this.user.codigo_rol == "CLIENTE") {
                        this.documentos = res.body.filter(
                            (val) => val.privado == false
                        );
                    } else {
                        this.documentos = res.body;
                    }
                    this.totalRows = this.documentos.length;
                    this.documentosCargando = false;
                    this.$v.form.nombreDocumento.$model = {
                        nombre_documento_id: this.documentoSeleccionado
                            .nombre_documento_id,
                        nombre_documento: this.documentoSeleccionado
                            .nombre_documento,
                    };
                })
                .catch((error) => {
                    this.documentosCargando = false;
                });
        },
        obtenerTipoDocumentosLocal() {
            this.tipoDocumentosCargando = true;
            this.obtenerTipoDocumentos()
                .then((res) => {
                    this.tipoDocumentos = res.body;
                    if (this.esEditar) {
                        this.$v.form.tipoDocumento.$model = {
                            tipo_documento_id: this.documentoSeleccionado
                                .tipo_documento_id,
                            nombre_tipo_documento: this.documentoSeleccionado
                                .nombre_tipo_documento,
                        };
                    }
                    this.tipoDocumentosCargando = false;
                })
                .catch((error) => {
                    this.tipoDocumentosCargando = false;
                });
        },
        obtenerTipoDocumentosFiltro() {
            this.tipoDocumentosCargando = true;
            this.obtenerTipoDocumentos()
                .then((res) => {
                    this.filtroTipoDocumentos.unshift({
                        tipo_documento_id: 0,
                        nombre_tipo_documento: "Seleccionar Tipo Documento",
                    });
                    res.body.map((item) => {
                        this.filtroTipoDocumentos.push(item);
                    })

                    this.tipoDocumentosCargando = false;
                })
                .catch((error) => {
                    this.tipoDocumentosCargando = false;
                });
        },
        obtenerNombreDocumentosLocal() {
            this.nombreDocumentosCargando = true;
            this.obtenerNombreDocumentos()
                .then((res) => {
                    this.nombreDocumentos = res.body;
                    this.nombreDocumentosCargando = false;
                    this.$v.form.nombreDocumento.$model = {
                        nombre_documento_id: this.documentoSeleccionado
                            .nombre_documento_id,
                        nombre_documento: this.documentoSeleccionado
                            .nombre_documento,
                    };
                })
                .catch((error) => {
                    this.nombreDocumentosCargando = false;
                });
        },
        obtenerNombreDocumentosFiltro() {
            this.nombreDocumentosCargando = true;
            this.obtenerNombreDocumentos()
                .then((res) => {
                    this.filtroNombreDocumentos.unshift({
                        nombre_documento_id: 0,
                        nombre_documento: "Seleccionar Nombre Documento",
                    });
                    res.body.map((item) => {
                        this.filtroNombreDocumentos.push(item);
                    })
                })
                .catch((error) => {
                    this.nombreDocumentosCargando = false;
                });
        },
        filtrarNombreDocumentos() {
            this.obtenerNombreDocumentosPorTipoDocumento(
                this.$v.form.tipoDocumento.$model.tipo_documento_id
            )
                .then((res) => {
                    this.nombreDocumentos = res.body;
                    this.nombreDocumentosCargando = false;
                })
                .catch((error) => {
                    this.nombreDocumentosCargando = false;
                });
        },
        actualizarEstadoPrivado(item) {
            this.loadingPrivado = true;
            this.actualizarPrivadoDocumento({ documento_id: item.documento_id })
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Documento actualizado correctamente!!!";
                        this.mostrarModal = false;
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerDocumentosLocal();
                    this.loadingPrivado = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loadingPrivado = false;
                });
        },

        submit(e) {
            this.loading = true;
            this.submitted = true;
            this.$v.form.$touch();

            // let validarFechas = false;
            // if (this.$v.form.fechaLimite.$model == null) {
            //     this.fechaLimiteValidar = false;
            //     validarFechas = true;
            // }

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }
            if (this.documentoParaSubir == null) {
                this.loading = false;
                return;
            }

            // if (validarFechas) {
            //     return;
            // }
            let documento_id = "";
            if (this.esEditar) {
                documento_id = this.documentoSeleccionado.documento_id;
            }
            const codigo_proyecto = this.codigoProyecto;
            const codigo_lugar_instalacion = this.codigoLugarInstalacion;
            const tipo_documento_id = this.$v.form.tipoDocumento.$model
                .tipo_documento_id;
            const nombre_tipo_documento = this.$v.form.tipoDocumento.$model
                .nombre_tipo_documento;
            const nombre_documento_id = this.$v.form.nombreDocumento.$model
                .nombre_documento_id;
            const nombre_documento = this.$v.form.nombreDocumento.$model
                .nombre_documento;
            const comentario = this.$v.form.comentario.$model;
            const now = new Date();
            const fecha_hoy = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;

            const fecha_limite =
                this.$v.form.fechaLimite.$model != null
                    ? this.$v.form.fechaLimite.$model
                    : null;

            const fecha_subida = "";
            const privado = 1; //this.$v.form.privado.$model;

            let formData = new FormData();
            formData.append("file", this.documentoParaSubir);

            formData.append(
                "documento",
                JSON.stringify({
                    documento_id,
                    codigo_proyecto,
                    codigo_lugar_instalacion,
                    tipo_documento_id,
                    nombre_tipo_documento,
                    nombre_documento_id,
                    nombre_documento,
                    comentario,
                    fecha_limite,
                    fecha_subida,
                    privado,
                })
            );
            if (this.esEditar) {
                if (this.documentoParaSubir == null) {
                    this.actualizarSinDocumento({
                        documento_id,
                        codigo_proyecto,
                        codigo_lugar_instalacion,
                        tipo_documento_id,
                        nombre_tipo_documento,
                        nombre_documento_id,
                        nombre_documento,
                        comentario,
                        fecha_limite,
                        fecha_subida,
                        privado,
                    })
                        .then((res) => {
                            if (res.status == 202) {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "Ha ocurrido un error, intente nuevamente";
                            }

                            if (res.status == 204) {
                                this.mensaje.variant = "success";
                                this.mensaje.texto =
                                    "Documento actualizado correctamente!!!";
                                this.mostrarModal = false;
                            }

                            this.segundosMensajeActualizacion = this.segundos;
                            this.obtenerDocumentosLocal();
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                            this.mostrarModal = false;
                            this.segundosMensajeActualizacion = this.segundos;
                            this.loading = false;
                        });
                } else {
                    this.actualizarDocumento(formData)
                        .then((res) => {
                            if (res.status == 202) {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "Ha ocurrido un error, intente nuevamente";
                            }

                            if (res.status == 204) {
                                this.mensaje.variant = "success";
                                this.mensaje.texto =
                                    "Documento actualizado correctamente!!!";
                                this.mostrarModal = false;
                            }

                            this.segundosMensajeActualizacion = this.segundos;
                            this.obtenerDocumentosLocal();
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                            this.mostrarModal = false;
                            this.segundosMensajeActualizacion = this.segundos;
                            this.loading = false;
                        });
                }
            } else {
                this.crearDocumento(formData)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Documento creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El documento ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerDocumentosLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                tipoDocumento: null,
                nombreDocumento: null,
                comentario: null,
                privado: null,
                fechaLimite: null,
            };
            this.documentoParaSubir = null;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarDocumentoLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarDocumento(item.documento_id)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Documento eliminada correctamente!!!";
                                    if (this.guardarCerrar) {
                                        this.mostrarModal = false;
                                    }
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerDocumentosLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        seleccionarIconoDocumento(extension) {
            if (extension == ".doc" || extension == ".docx") {
                return "mdi-file-word text-primary";
            } else if (extension == ".xls" || extension == ".xlsx") {
                return "mdi-file-excel text-success";
            } else if (extension == ".pdf") {
                return "mdi-file-pdf text-danger";
            } else if (extension == ".doc" || extension == ".docx") {
                return "mdi-file-document text-primary";
            } else if (
                extension == ".jpg" ||
                extension == ".jpeg" ||
                extension == ".png" ||
                extension == ".bmp"
            ) {
                return "mdi-file-image text-warning";
            } else {
                return "mdi-file-document text-primary";
            }
        },
        irLugarinstalacion(proyecto) {
            this.seleccionarProyecto(proyecto[0]);
            this.$router.push({
                name: "lugaresInstalacion",
                params: { codigoProyecto: proyecto[0].codigo_proyecto },
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>
